import { Application } from "@hotwired/stimulus"
import { registerControllers } from "stimulus-vite-helpers"

const application = Application.start()

const componentModules = import.meta.glob("../../components/**/*_controller.js", {
  eager: true,
})
registerControllers(application, componentModules)

const controllers = import.meta.glob("./**/*_controller.js", {
  eager: true,
})
registerControllers(application, controllers)
