import ApplicationController from "@/controllers/application_controller"

export default class extends ApplicationController {
  static targets = []
  static values = {}

  initialize() {}

  connect() {
  }

  disconnect() {}
}
