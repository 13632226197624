import ApplicationController from "@/controllers/application_controller"
import Tooltip from "../../../../../javascript/lib/tooltip"

export default class extends ApplicationController {
  static targets = ["rangeSlider", "currentValue", "maxValueText"]
  static values = {
    maxAllowed: Number | null,
    maxAllowedMessage: String,
  }

  initialize() {}

  connect() {
    this.tooltipInstance = Tooltip.attachToElement({
      element: this.rangeSliderTarget,
      content: this.maxAllowedMessageValue || "To use more upgrade plan.",
    })

    this.tooltipInstance.disable()
    this.updateCurrentValue(this.rangeSliderTarget.value)
  }

  disconnect() {}

  refreshMaxValueText() {
    this.maxValueTextTarget.innerHTML = this.rangeSliderTarget.max
  }

  updateCurrentValue(value) {
    this.currentValueTarget.innerHTML = value
    this.refreshMaxValueText()
  }

  handleChange(e) {
    let current = e.target.value
    if (this.maxAllowedValue > 0 && parseInt(current) > this.maxAllowedValue) {
      this.tooltipInstance.enable()
      this.tooltipInstance.show()
      this.rangeSliderTarget.value = this.maxAllowedValue
      current = this.maxAllowedValue
    } else {
      this.tooltipInstance.disable()
    }

    this.updateCurrentValue(current)
  }
}
