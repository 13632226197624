import ApplicationController from "@/controllers/application_controller"

export default class extends ApplicationController {
  static targets = ["dropdown", "overlay"]

  initialize() {}
  connect() {}

  closeDropdown() {
    this.dropdownTarget.classList.add("hidden")
    this.overlayTarget.classList.add("hidden")
  }

  toggleDropdown() {
    this.dropdownTarget.classList.toggle("hidden")
    this.overlayTarget.classList.toggle("hidden")
  }
}
