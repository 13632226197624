import {
  ApplicationController as ApplicationControllerStimulusUse,
  useApplication,
} from "stimulus-use"

export default class extends ApplicationControllerStimulusUse {
  initialize() {}

  connect() {
    useApplication(this)
    document.addEventListener(
      "turbo:frame-missing",
      this.onTurboFrameMissing.bind(this),
    )
  }

  disconnect() {
    document.removeEventListener(
      "turbo:frame-missing",
      this.onTurboFrameMissing.bind(this),
    )
  }

  onTurboFrameMissing(event) {
    const {
      detail: { response, visit },
    } = event
    event.preventDefault()
    visit(response) // you have to render your "application" layout for this
  }

  childController(element, { controller } = {}) {
    return this.application.getControllerForElementAndIdentifier(
      element,
      controller || element.dataset?.controller,
    )
  }

  parentController(controllerName) {
    const parent = this.element.closest(`[data-controller~='${controllerName}']`)

    return this.application.getControllerForElementAndIdentifier(
      parent,
      controllerName,
    )
  }
}
