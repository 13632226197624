import ApplicationController from "@/controllers/application_controller"

export default class extends ApplicationController {
  initialize() {}

  connect() {}

  disconnect() {}

  isOpen() {
    return this.element.classList.contains("open")
  }

  open() {
    this.element.classList.add("open")
    this.element.classList.remove("close")
  }

  close() {
    this.element.classList.add("close")
    this.element.classList.remove("open")
  }

  toggle() {
    if (this.isOpen()) {
      this.close()
    } else this.open()
  }
}
