import ApplicationController from "@/controllers/application_controller"

export default class extends ApplicationController {
  static targets = ["select", "icon"]
  static values = {}

  initialize() {}

  connect() {
    this.setIconOption()
  }

  disconnect() {}

  setIconOption() {
    const icon = this.selectedOption().dataset.icon
    if (icon && this.hasIconTarget) {
      this.iconTarget.innerHTML = icon
      this.iconTarget.classList.remove("hidden")
    } else {
      this.iconTarget.innerHTML = null
      this.iconTarget.classList.add("hidden")
    }
  }

  selectedOption() {
    const selectedIndex = this.selectTarget.selectedIndex
    return this.selectTarget.options[selectedIndex]
  }

  onChange() {
    this.setIconOption()
  }
}
