import ApplicationController from "@/controllers/application_controller"

export default class extends ApplicationController {
  static values = {
    parentController: String,
    onUploadCallback: String,
  }

  static targets = ["fileInput"]

  initialize() {}

  parentController() {
    const parent = this.element.closest(
      `[data-controller~='${this.parentControllerValue}']`
    )

    return this.application.getControllerForElementAndIdentifier(
      parent,
      this.parentControllerValue
    )
  }


  onUploadClick(e) {
    e.preventDefault()
    this.fileInputTarget.click()
  }

  onFileUpload(e) {
    const callback = this.parentController(this.parentControllerValue)[
      this.onUploadCallbackValue
    ]?.bind(this.parentController())
    if (callback) {
      callback(e)
    }
  }
}
